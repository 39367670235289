export const NEW_CATEGORY_REQUEST = 'NEW_CATEGORY_REQUEST'
export const NEW_CATEGORY_SUCCESS = 'NEW_CATEGORY_SUCCESS'
export const NEW_CATEGORY_RESET = 'NEW_CATEGORY_RESET'
export const NEW_CATEGORY_FAIL = 'NEW_CATEGORY_FAIL'

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_RESET = 'GET_CATEGORIES_RESET'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'

export const SINGLE_CATEGORY_REQUEST = 'SINGLE_CATEGORY_REQUEST'
export const SINGLE_CATEGORY_SUCCESS = 'SINGLE_CATEGORY_SUCCESS'
export const SINGLE_CATEGORY_RESET = 'SINGLE_CATEGORY_RESET'
export const SINGLE_CATEGORY_FAIL = 'SINGLE_CATEGORY_FAIL'



export const CLEAR_ERRORS = 'CLEAR_ERRORS'
