export const ALL_SERVICES_REQUEST = 'ALL_SERVICES_REQUEST'
export const ALL_SERVICES_SUCCESS = 'ALL_SERVICES_SUCCESS'
export const ALL_SERVICES_FAIL = 'ALL_SERVICES_FAIL'

// export const FULL_SERVICES_REQUEST = 'FULL_SERVICES_REQUEST'
// export const FULL_SERVICES_SUCCESS = 'FULL_SERVICES_SUCCESS'
// export const FULL_SERVICES_FAIL = 'FULL_SERVICES_FAIL'

export const SERVICE_DETAILS_REQUEST = 'SERVICE_DETAILS_REQUEST'
export const SERVICE_DETAILS_SUCCESS = 'SERVICE_DETAILS_SUCCESS'
export const SERVICE_DETAILS_FAIL = 'SERVICE_DETAILS_FAIL'

export const NEW_SERVICES_REQUEST = 'NEW_SERVICES_REQUEST'
export const NEW_SERVICES_SUCCESS = 'NEW_SERVICES_SUCCESS'
export const NEW_SERVICES_RESET = 'NEW_SERVICES_RESET'
export const NEW_SERVICES_FAIL = 'NEW_SERVICES_FAIL'

export const UPDATE_SERVICES_REQUEST = 'UPDATE_SERVICES_REQUEST'
export const UPDATE_SERVICES_SUCCESS = 'UPDATE_SERVICES_SUCCESS'
export const UPDATE_SERVICES_RESET = 'UPDATE_SERVICES_RESET'
export const UPDATE_SERVICES_FAIL = 'UPDATE_SERVICES_FAIL'

export const DELETE_SERVICES_REQUEST = 'DELETE_SERVICES_REQUEST'
export const DELETE_SERVICES_SUCCESS = 'DELETE_SERVICES_SUCCESS'
export const DELETE_SERVICES_RESET = 'DELETE_SERVICES_RESET'
export const DELETE_SERVICES_FAIL = 'DELETE_SERVICES_FAIL'

export const SINGLE_SERVICE_REQUEST = 'SINGLE_SERVICE_REQUEST'
export const SINGLE_SERVICE_SUCCESS = 'SINGLE_SERVICE_SUCCESS'
export const SINGLE_SERVICE_FAIL = 'SINGLE_SERVICE_SUCCESS'

export const FREELANCER_SERVICES_REQUEST = 'FREELANCER_SERVICES_REQUEST'
export const FREELANCER_SERVICES_SUCCESS = 'FREELANCER_SERVICES_SUCCESS'
export const FREELANCER_SERVICES_FAIL = 'FREELANCER_SERVICES_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
