export const NEW_RATING_REQUEST = 'NEW_RATING_REQUEST'
export const NEW_RATING_SUCCESS = 'NEW_RATING_SUCCESS'
export const NEW_RATING_RESET = 'NEW_RATING_RESET'
export const NEW_RATING_FAIL = 'NEW_RATING_FAIL'

export const GET_RATINGS_REQUEST = 'GET_RATINGS_REQUEST'
export const GET_RATINGS_SUCCESS = 'GET_RATINGS_SUCCESS'
export const GET_RATINGS_RESET = 'GET_RATINGS_RESET'
export const GET_RATINGS_FAIL = 'GET_RATINGS_FAIL'

export const SINGLE_RATING_REQUEST = 'SINGLE_RATING_REQUEST'
export const SINGLE_RATING_SUCCESS = 'SINGLE_RATING_SUCCESS'
export const SINGLE_RATING_RESET = 'SINGLE_RATING_RESET'
export const SINGLE_RATING_FAIL = 'SINGLE_RATING_FAIL'



export const CLEAR_ERRORS = 'CLEAR_ERRORS'
