

export const GET_CHATS_REQUEST = 'GET_CHATS_REQUEST'
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS'
export const GET_CHATS_RESET = 'GET_CHATS_RESET'
export const GET_CHATS_FAIL = 'GET_CHATS_FAIL'





export const CLEAR_ERRORS = 'CLEAR_ERRORS'
