import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'



import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom';

const Contact_us = () => {



    return (
        <h1 className='gitna'>Contact Us</h1>
    )
}

export default Contact_us