export const NEW_REPORT_REQUEST = 'NEW_REPORT_REQUEST'
export const NEW_REPORT_SUCCESS = 'NEW_REPORT_SUCCESS'
export const NEW_REPORT_RESET = 'NEW_REPORT_RESET'
export const NEW_REPORT_FAIL = 'NEW_REPORT_FAIL'

export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST'
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS'
export const GET_REPORTS_RESET = 'GET_REPORTS_RESET'
export const GET_REPORTS_FAIL = 'GET_REPORTS_FAIL'

export const SINGLE_REPORT_REQUEST = 'SINGLE_REPORT_REQUEST'
export const SINGLE_REPORT_SUCCESS = 'SINGLE_REPORT_SUCCESS'
export const SINGLE_REPORT_RESET = 'SINGLE_REPORT_RESET'
export const SINGLE_REPORT_FAIL = 'SINGLE_REPORT_FAIL'



export const CLEAR_ERRORS = 'CLEAR_ERRORS'
