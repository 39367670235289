export const NEW_OFFER_REQUEST = 'NEW_OFFER_REQUEST'
export const NEW_OFFER_SUCCESS = 'NEW_OFFER_SUCCESS'
export const NEW_OFFER_RESET = 'NEW_OFFER_RESET'
export const NEW_OFFER_FAIL = 'NEW_OFFER_FAIL'

export const GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST'
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS'
export const GET_OFFERS_RESET = 'GET_OFFERS_RESET'
export const GET_OFFERS_FAIL = 'GET_OFFERS_FAIL'

export const SINGLE_OFFER_REQUEST = 'SINGLE_OFFER_REQUEST'
export const SINGLE_OFFER_SUCCESS = 'SINGLE_OFFER_SUCCESS'
export const SINGLE_OFFER_RESET = 'SINGLE_OFFER_RESET'
export const SINGLE_OFFER_FAIL = 'SINGLE_OFFER_FAIL'

export const REQUEST_OFFERS_REQUEST = 'REQUEST_OFFERS_REQUEST'
export const REQUEST_OFFERS_SUCCESS = 'REQUEST_OFFERS_SUCCESS'
export const REQUEST_OFFERS_RESET = 'REQUEST_OFFERS_RESET'
export const REQUEST_OFFERS_FAIL = 'REQUEST_OFFERS_FAIL'

export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS'
export const UPDATE_STATUS_RESET = 'UPDATE_STATUS_RESET'
export const UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL'

export const ACCEPT_OFFER_REQUEST = 'ACCEPT_OFFER_REQUEST'
export const ACCEPT_OFFER_SUCCESS = 'ACCEPT_OFFER_SUCCESS'
export const ACCEPT_OFFER_RESET = 'ACCEPT_OFFER_RESET'
export const ACCEPT_OFFER_FAIL = 'ACCEPT_OFFER_FAIL'

export const UPDATE_OFFER_REQUEST = 'UPDATE_OFFER_REQUEST'
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS'
export const UPDATE_OFFER_RESET = 'UPDATE_OFFER_RESET'
export const UPDATE_OFFER_FAIL = 'UPDATE_OFFER_FAIL'


export const CLEAR_ERRORS = 'CLEAR_ERRORS'
