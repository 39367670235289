

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_RESET = 'GET_MESSAGES_RESET'
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL'


export const NEW_MESSAGE_REQUEST = 'NEW_MESSAGE_REQUEST'
export const NEW_MESSAGE_SUCCESS = 'NEW_MESSAGE_SUCCESS'
export const NEW_MESSAGE_RESET = 'NEW_MESSAGE_RESET'
export const NEW_MESSAGE_FAIL = 'NEW_MESSAGE_FAIL'



export const CLEAR_ERRORS = 'CLEAR_ERRORS'
