
export const SINGLE_FREELANCER_REQUEST = 'SINGLE_FREELANCER_REQUEST'
export const SINGLE_FREELANCER_SUCCESS = 'SINGLE_FREELANCER_SUCCESS'
export const SINGLE_FREELANCER_FAIL = 'SINGLE_FREELANCER_SUCCESS'

export const NEW_FREELANCER_REQUEST = 'NEW_FREELANCER_REQUEST'
export const NEW_FREELANCER_SUCCESS = 'NEW_FREELANCER_SUCCESS'
export const NEW_FREELANCER_FAIL = 'NEW_FREELANCER_SUCCESS'
export const NEW_FREELANCER_RESET = 'NEW_FREELANCER_RESET'

export const GET_FREELANCERS_REQUEST = 'GET_FREELANCERS_REQUEST'
export const GET_FREELANCERS_SUCCESS = 'GET_FREELANCERS_SUCCESS'
export const GET_FREELANCERS_FAIL = 'GET_FREELANCERS_SUCCESS'

export const GET_APPLICATION_REQUEST = 'GET_APPLICATION_REQUEST'
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS'
export const GET_APPLICATION_FAIL = 'GET_APPLICATION_SUCCESS'

export const APPROVE_APPLICATION_REQUEST = 'APPROVE_APPLICATION_REQUEST'
export const APPROVE_APPLICATION_SUCCESS = 'APPROVE_APPLICATION_SUCCESS'
export const APPROVE_APPLICATION_FAIL = 'APPROVE_APPLICATION_SUCCESS'
export const APPROVE_APPLICATION_RESET = 'APPROVE_APPLICATION_RESET'

export const REJECT_APPLICATION_REQUEST = 'REJECT_APPLICATION_REQUEST'
export const REJECT_APPLICATION_SUCCESS = 'REJECT_APPLICATION_SUCCESS'
export const REJECT_APPLICATION_FAIL = 'REJECT_APPLICATION_SUCCESS'
export const REJECT_APPLICATION_RESET = 'REJECT_APPLICATION_RESET'

export const AVAIL_PREMIUM_REQUEST = 'AVAIL_PREMIUM_REQUEST'
export const AVAIL_PREMIUM_SUCCESS = 'AVAIL_PREMIUM_SUCCESS'
export const AVAIL_PREMIUM_FAIL = 'AVAIL_PREMIUM_SUCCESS'
export const AVAIL_PREMIUM_RESET = 'AVAIL_PREMIUM_RESET'

export const GET_APPLICATIONPREMIUM_REQUEST = 'GET_APPLICATIONPREMIUM_REQUEST'
export const GET_APPLICATIONPREMIUM_SUCCESS = 'GET_APPLICATIONPREMIUM_SUCCESS'
export const GET_APPLICATIONPREMIUM_FAIL = 'GET_APPLICATIONPREMIUM_SUCCESS'

export const APPROVE_APPLICATIONPREMIUM_REQUEST = 'APPROVE_APPLICATIONPREMIUM_REQUEST'
export const APPROVE_APPLICATIONPREMIUM_SUCCESS = 'APPROVE_APPLICATIONPREMIUM_SUCCESS'
export const APPROVE_APPLICATIONPREMIUM_FAIL = 'APPROVE_APPLICATIONPREMIUM_SUCCESS'
export const APPROVE_APPLICATIONPREMIUM_RESET = 'APPROVE_APPLICATIONPREMIUM_RESET'

export const REJECT_APPLICATIONPREMIUM_REQUEST = 'REJECT_APPLICATIONPREMIUM_REQUEST'
export const REJECT_APPLICATIONPREMIUM_SUCCESS = 'REJECT_APPLICATIONPREMIUM_SUCCESS'
export const REJECT_APPLICATIONPREMIUM_FAIL = 'REJECT_APPLICATIONPREMIUM_SUCCESS'
export const REJECT_APPLICATIONPREMIUM_RESET = 'REJECT_APPLICATIONPREMIUM_RESET'

export const AVAILABILITY_UPDATE_REQUEST = 'AVAILABILITY_UPDATE_REQUEST'
export const AVAILABILITY_UPDATE_SUCCESS = 'AVAILABILITY_UPDATE_SUCCESS'
export const AVAILABILITY_UPDATE_FAIL =    'AVAILABILITY_UPDATE_SUCCESS'
export const AVAILABILITY_UPDATE_RESET =   'AVAILABILITY_UPDATE_RESET'

export const FREELANCER_SETUP_REQUEST = 'FREELANCER_SETUP_REQUEST'
export const FREELANCER_SETUP_SUCCESS = 'FREELANCER_SETUP_SUCCESS'
export const FREELANCER_SETUP_FAIL =    'FREELANCER_SETUP_SUCCESS'
export const FREELANCER_SETUP_RESET =   'FREELANCER_SETUP_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
