export const NEW_TRANSACTION_REQUEST = 'NEW_TRANSACTION_REQUEST'
export const NEW_TRANSACTION_SUCCESS = 'NEW_TRANSACTION_SUCCESS'
export const NEW_TRANSACTION_RESET = 'NEW_TRANSACTION_RESET'
export const NEW_TRANSACTION_FAIL = 'NEW_TRANSACTION_FAIL'

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST'
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_RESET = 'GET_TRANSACTIONS_RESET'
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL'

export const SINGLE_TRANSACTION_REQUEST = 'SINGLE_TRANSACTION_REQUEST'
export const SINGLE_TRANSACTION_SUCCESS = 'SINGLE_TRANSACTION_SUCCESS'
export const SINGLE_TRANSACTION_RESET = 'SINGLE_TRANSACTION_RESET'
export const SINGLE_TRANSACTION_FAIL = 'SINGLE_TRANSACTION_FAIL'

export const UPDATE_PSENT_REQUEST = 'UPDATE_PSENT_REQUEST'
export const UPDATE_PSENT_SUCCESS = 'UPDATE_PSENT_SUCCESS'
export const UPDATE_PSENT_RESET = 'UPDATE_PSENT_RESET'
export const UPDATE_PSENT_FAIL = 'UPDATE_PSENT_FAIL'

export const UPDATE_PRECEIVED_REQUEST = 'UPDATE_PRECEIVED_REQUEST'
export const UPDATE_PRECEIVED_SUCCESS = 'UPDATE_PRECEIVED_SUCCESS'
export const UPDATE_PRECEIVED_RESET = 'UPDATE_PRECEIVED_RESET'
export const UPDATE_PRECEIVED_FAIL = 'UPDATE_PRECEIVED_FAIL'

export const UPDATE_TRANSACTIONDONE_REQUEST = 'UPDATE_TRANSACTIONDONE_REQUEST'
export const UPDATE_TRANSACTIONDONE_SUCCESS = 'UPDATE_TRANSACTIONDONE_SUCCESS'
export const UPDATE_TRANSACTIONDONE_RESET = 'UPDATE_TRANSACTIONDONE_RESET'
export const UPDATE_TRANSACTIONDONE_FAIL = 'UPDATE_TRANSACTIONDONE_FAIL'

export const UPDATE_RATEDONE_REQUEST = 'UPDATE_RATEDONE_REQUEST'
export const UPDATE_RATEDONE_SUCCESS = 'UPDATE_RATEDONE_SUCCESS'
export const UPDATE_RATEDONE_RESET = 'UPDATE_RATEDONE_RESET'
export const UPDATE_RATEDONE_FAIL = 'UPDATE_RATEDONE_FAIL'

export const UPDATE_REPORTDONE_REQUEST = 'UPDATE_REPORTDONE_REQUEST'
export const UPDATE_REPORTDONE_SUCCESS = 'UPDATE_REPORTDONE_SUCCESS'
export const UPDATE_REPORTDONE_RESET = 'UPDATE_REPORTDONE_RESET'
export const UPDATE_REPORTDONE_FAIL = 'UPDATE_REPORTDONE_FAIL'

export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_RESET = 'UPDATE_TRANSACTION_RESET'
export const UPDATE_TRANSACTION_FAIL = 'UPDATE_TRANSACTION_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
