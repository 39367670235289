import React, { Fragment } from 'react'
const Footer = () => {
    return (
        <Fragment>
            <center style={{ paddingTop: '25px' }}>
            <h3 style={{ paddingBottom: '25px' }}> This page is Under Construction</h3>
            <img className='underconstruction' src="../images/underconstruction.png" />
            </center>
        </Fragment>
    )
}
export default Footer