export const NEW_REQUEST_REQUEST = 'NEW_REQUEST_REQUEST'
export const NEW_REQUEST_SUCCESS = 'NEW_REQUEST_SUCCESS'
export const NEW_REQUEST_RESET = 'NEW_REQUEST_RESET'
export const NEW_REQUEST_FAIL = 'NEW_REQUEST_FAIL'

export const GET_REQUESTS_REQUEST = 'GET_REQUESTS_REQUEST'
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS'
export const GET_REQUESTS_RESET = 'GET_REQUESTS_RESET'
export const GET_REQUESTS_FAIL = 'GET_REQUESTS_FAIL'

export const SINGLE_REQUEST_REQUEST = 'SINGLE_REQUEST_REQUEST'
export const SINGLE_REQUEST_SUCCESS = 'SINGLE_REQUEST_SUCCESS'
export const SINGLE_REQUEST_RESET = 'SINGLE_REQUEST_RESET'
export const SINGLE_REQUEST_FAIL = 'SINGLE_REQUEST_FAIL'



export const CLEAR_ERRORS = 'CLEAR_ERRORS'
