export const NEW_INQUIRY_REQUEST = 'NEW_INQUIRY_REQUEST'
export const NEW_INQUIRY_SUCCESS = 'NEW_INQUIRY_SUCCESS'
export const NEW_INQUIRY_RESET = 'NEW_INQUIRY_RESET'
export const NEW_INQUIRY_FAIL = 'NEW_INQUIRY_FAIL'

export const ALL_INQUIRY_REQUEST = 'ALL_INQUIRY_REQUEST'
export const ALL_INQUIRY_SUCCESS = 'ALL_INQUIRY_SUCCESS'
export const ALL_INQUIRY_RESET = 'ALL_INQUIRY_RESET'
export const ALL_INQUIRY_FAIL = 'ALL_INQUIRY_FAIL'

export const MY_INQUIRY_REQUEST = 'MY_INQUIRY_REQUEST'
export const MY_INQUIRY_SUCCESS = 'MY_INQUIRY_SUCCESS'
export const MY_INQUIRY_RESET = 'MY_INQUIRY_RESET'
export const MY_INQUIRY_FAIL = 'MY_INQUIRY_FAIL'

export const CLIENT_INQUIRY_REQUEST = 'CLIENT_INQUIRY_REQUEST'
export const CLIENT_INQUIRY_SUCCESS = 'CLIENT_INQUIRY_SUCCESS'
export const CLIENT_INQUIRY_RESET = 'CLIENT_INQUIRY_RESET'
export const CLIENT_INQUIRY_FAIL = 'CLIENT_INQUIRY_FAIL'

export const SINGLE_INQUIRY_REQUEST = 'SINGLE_INQUIRY_REQUEST'
export const SINGLE_INQUIRY_SUCCESS = 'SINGLE_INQUIRY_SUCCESS'
export const SINGLE_INQUIRY_RESET = 'SINGLE_INQUIRY_RESET'
export const SINGLE_INQUIRY_FAIL = 'SINGLE_INQUIRY_FAIL'

export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS'
export const UPDATE_STATUS_RESET = 'UPDATE_STATUS_RESET'
export const UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL'




export const CLEAR_ERRORS = 'CLEAR_ERRORS'
